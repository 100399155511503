import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

function Layout4() {
  useEffect(() => {
    Aos.init();
  });

  return (
    <div className="Layout_4">
      <div className="Layout_4_Contents">
        <div
          className="Layout_4_Images"
          data-aos="fade-up"
          data-aos-offset="120"
          data-aos-easing="ease"
          data-aos-duration="1000"
          data-aos-anchor-placement="top-bottom"
        >
          <img
            src={require("../Assets/Images/newImg3.png")}
            alt="Img3"
            width="100%"
            height="autoHeight"
            style={{ maxWidth: "520px" }}
          />
        </div>
        <div
          className="Layout_4_Text"
          data-aos="fade-up"
          data-aos-offset="120"
          data-aos-easing="ease"
          data-aos-duration="1000"
          data-aos-anchor-placement="top-bottom"
        >
          <div className="Copy_2_600_A">전송까지 간편하게</div>
          <div className="Copy_2_600_A">
            앱에서 <span style={{ color: "rgb(55, 105, 252)" }}>한번에</span>
          </div>
          <div className="Layout_4_SubText">
            <div
              className="Copy_6_400_B"
              style={{ color: "rgb(130, 130, 130)" }}
            >
              재직증명서를 전송 할 때 써티드의 템플릿을 통해 내용 고민없이 그저
              전송 버튼만 누르면 됩니다.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout4;
