import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

const Layout1 = () => {
  return (
    <div className="Layout_1">
      <div className="Layout_1_Illust">
        <div className="Layout_1_Illust_Crop">
          <img
            src={require("../Assets/Images/FinalWhite.png")}
            alt="headerIllust"
            width="100%"
            height="autoHeight"
            id="headerImage"
          />
        </div>
      </div>
      <div className="Layout_1_Contents">
        <div className="Layout_1_Text">
          <div className="Copy_1_200_A" style={{ color: "rgb(70, 70, 70)" }}>
            직장은 잠깐이지만
          </div>
          <div className="Copy_1_200_A" style={{ color: "rgb(70, 70, 70)" }}>
            경력은 영원하니까
          </div>
          <div
            className="Copy_1_200_A"
            style={{ color: "rgb(70, 70, 70)", fontWeight: 700 }}
          >
            써티드
          </div>
          <div
            className="Copy_6_400_A"
            style={{ marginTop: "5%", color: "rgb(70, 70, 70)" }}
          >
            증명서 발급 부터 전송까지 한번에!
          </div>
          <div className="Layout_1_ButtonCT">
            <div className="Layout_1_Apple">
              <button
                className="Layout_1_ButtonBG"
                onClick={() => {
                  window.location.href =
                    "https://certifie.onelink.me/2vIN/landing01";
                }}
              >
                <img
                  src={require("../Assets/Images/appleLogoBLK.png")}
                  alt="appleLogo"
                  width="100%"
                  height="autoHeight"
                  style={{
                    maxWidth: "32px",
                    display: "flex",
                    marginRight: "3%",
                  }}
                />
                <div className="Copy_5_500_A">App Store</div>
              </button>
            </div>
            <div className="Layout_1_Google">
              <button
                className="Layout_1_ButtonBG"
                onClick={() => {
                  window.location.href =
                    "https://certifie.onelink.me/2vIN/landing01";
                }}
              >
                <img
                  src={require("../Assets/Images/GooglePlayLogo.png")}
                  alt="GooglePlayLogo"
                  width="100%"
                  height="autoHeight"
                  style={{
                    maxWidth: "32px",
                    display: "flex",
                    marginRight: "3%",
                  }}
                />
                <div className="Copy_5_500_A">Google Play</div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layout1;
