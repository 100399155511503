import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

function Layout6() {
  useEffect(() => {
    Aos.init();
  });
  return (
    <div className="Layout_6">
      <div
        className="Layout_6_Text"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-easing="ease"
        data-aos-duration="1000"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="Copy_2_600_A" style={{ color: "rgb(55, 105, 252)" }}>
          한 눈에 알아보는
        </div>
        <div className="Copy_2_600_A">써티드의 경력증명서</div>
        <div className="Layout_6_SubText">
          <div
            className="Copy_6_400_B"
            style={{ color: "rgb(130, 130, 130)", textAlign: "center" }}
          >
            앱으로 발급 받고 앱에서 직접 전송하기 때문에
            <br />
            안전하고 투명하게 관리되어 위변조 위험을 방지합니다.
          </div>
        </div>
      </div>
      <div
        className="Layout_6_Images"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-easing="ease"
        data-aos-duration="1000"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="Layout_6_Image_CT_1">
          <img
            src={require("../Assets/Images/CertificateDetail.png")}
            alt="Detail"
            width="100%"
            height="autoHeight"
          />
        </div>
        <div className="Layout_6_Image_CT_2">
          <img
            src={require("../Assets/Images/CertificatePDF.png")}
            alt="Detail"
            width="100%"
            height="autoHeight"
          />
        </div>
      </div>
    </div>
  );
}

export default Layout6;
