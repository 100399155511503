import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

function Layout8() {
  return (
    <div className="Layout_8">
      <div className="Layout_8_Content">
        <div className="Layout_8_TitleCT">
          <div className="Layout_8_TitleBG">
            <div className="Copy_3_700_A">자주하는 질문</div>
          </div>
        </div>
        <div className="Layout_8_Text">
          <div className="Copy_4_700_A">무료로 사용할 수 있나요?</div>
          <div className="Copy_6_500_B">
            네, 써티드의 재직증명서 발급은 무료입니다. 블록체인으로 안전하게
            관리되는 재직증명서를 무료로 발급 받으실 수 있습니다.
          </div>
          {/* <div className="Layout_8_LineCT">
            <div className="Layout_8_Line" />
          </div>
          <div className="Copy_4_700_A">
            우리 회사가 서비스를 신청하지 않아도 경력증명서 발급이 가능한가요?
          </div>
          <div className="Copy_6_500_B">
            네, 기업 인증을 하지 않아도 경력증명서 발급이 가능합니다. 650만건의
            기업/기관 데이터를 통해 재직하신 곳의 경력증명서를 발급 받을 수
            있습니다.
          </div> */}
          <div className="Layout_8_LineCT">
            <div className="Layout_8_Line" />
          </div>
          <div className="Copy_4_700_A">발급일 갱신이 왜 필요한가요?</div>
          <div className="Copy_6_500_B">
            기업/기관에서 최근 3개월, 1년 이내 발급된 경력증명서를 요구하는
            경우, 재직했던 곳에 다시 연락하여 발급받지 않고 발급일을 갱신 할 수
            있습니다.
          </div>
          <div className="Layout_8_LineCT">
            <div className="Layout_8_Line" />
          </div>
          <div className="Copy_4_700_A">추후 유료 전환 예정인가요?</div>
          <div className="Copy_6_500_B">
            경력증명서 발급일 갱신, 기업/기관의 경력증명서 인증 등 재직증명서
            발급 이외의 추가 서비스는 이벤트 기간 2개월 이후 유료 전환
            예정입니다.
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout8;
