import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

function Layout9() {
  return (
    <div className="Layout_9">
      <div className="Layout_9_Line" />
      <div className="Layout_9_Title">
        <div className="Layout_9_Logo">
          <img
            src={require("../Assets/Logo/CertedLogo.png")}
            alt="Logo"
            width="97.2px"
            height="22.8px"
          />
        </div>
        <div className="Copy_6_500_C">㈜ 지디피스튜디오</div>
      </div>
      <div className="Layout_9_Text">
        <div
          className="Copy_7_400_A"
          style={{ marginTop: "27px", marginLeft: 0, marginRight: 0 }}
        >
          사업자 등록번호 : 146-87-02284 | 대표 : 이 유
        </div>
        <div
          className="Copy_7_400_A"
          style={{ marginTop: "2px", marginLeft: 0, marginRight: 0 }}
        >
          호스팅 서비스 : 주식회사 지디피스튜디오
        </div>
        <div
          className="Copy_7_400_A"
          style={{ marginTop: "2px", marginLeft: 0, marginRight: 0 }}
        >
          사업자정보확인
        </div>
        <div
          className="Copy_7_400_A"
          style={{ marginTop: "2px", marginLeft: 0, marginRight: 0 }}
        >
          주소 : 서울특별시 금천구 가산디지털1로 120 서울디지털산업단지
          G밸리창업큐브 603 (08590)
        </div>
        <div
          className="Copy_7_400_A"
          style={{ marginTop: "2px", marginLeft: 0, marginRight: 0 }}
        >
          대표번호 : 010-6212-0225
        </div>
        <div className="Copy_7_400_A" style={{ marginLeft: 0, marginRight: 0 }}>
          이메일 : help@certifie.io
        </div>
      </div>
      <div className="Layout_9_Text">
        <div
          className="Copy_7_400_A"
          style={{
            marginTop: "20px",
            marginLeft: 0,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.href = "https://certed.ing/terms-of-service/";
          }}
        >
          서비스 이용약관
        </div>
        <div
          className="Copy_7_400_A"
          style={{
            marginTop: "2px",
            marginBottom: "40px",
            marginLeft: 0,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={() => {
            window.location.href = "https://certed.ing/privacy-policy/";
          }}
        >
          개인정보 처리방침
        </div>
      </div>
    </div>
  );
}

export default Layout9;
