import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

function Layout3() {
  useEffect(() => {
    Aos.init();
  });

  return (
    <div className="Layout_3">
      <div
        className="Layout_3_Text"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-easing="ease"
        data-aos-duration="1000"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="Copy_2_600_A">
          <span style={{ color: "rgb(55, 105, 252)" }}>원스텝</span>으로
        </div>
        <div className="Copy_2_600_A">손쉽게 발급하고</div>
        <div className="Layout_3_SubText">
          <div className="Copy_6_400_B" style={{ color: "rgb(130, 130, 130)" }}>
            재직증명서 발급에 필요한 모든 과정을 앱에서 원스텝으로 진행하여
            부담없이 언제든지 발급 받을 수 있습니다.
          </div>
        </div>
      </div>
      <div
        className="Layout_3_Images"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-easing="ease"
        data-aos-duration="1000"
        data-aos-anchor-placement="top-bottom"
      >
        <img
          src={require("../Assets/Images/newImg2.png")}
          alt="Img2"
          width="100%"
          height="autoHeight"
          style={{ maxWidth: "520px" }}
        />
      </div>
    </div>
  );
}

export default Layout3;
