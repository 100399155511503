import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

function Layout5() {
  useEffect(() => {
    Aos.init();
  });

  return (
    <div className="Layout_5">
      <div
        className="Layout_5_Text"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-easing="ease"
        data-aos-duration="1000"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="Copy_2_600_A">이력 관리까지</div>
        <div className="Copy_2_600_A">
          써티드
          <span style={{ color: "rgb(55, 105, 252)" }}> 하나로</span>
        </div>
        <div className="Layout_5_SubText">
          <div className="Copy_6_400_B" style={{ color: "rgb(130, 130, 130)" }}>
            경력사항, 학력사항, 자격증사항, 포트폴리오를 앱하나로 관리 할 수
            있습니다.
          </div>
        </div>
      </div>
      <div
        className="Layout_5_Images"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-easing="ease"
        data-aos-duration="1000"
        data-aos-anchor-placement="top-bottom"
      >
        <img
          src={require("../Assets/Images/newImg4.png")}
          alt="Img4"
          width="100%"
          height="autoHeight"
          style={{ maxWidth: "520px" }}
        />
      </div>
    </div>
  );
}

export default Layout5;
