import "./GlobalStyle.css";
import "./PCStyle.css";
import "./TabletStyle.css";
import "./MobileStyle.css";
import React, { useEffect } from "react";

import Layout1 from "./Components/Layout1";
import Layout2 from "./Components/Layout2";
import Layout3 from "./Components/Layout3";
import Layout4 from "./Components/Layout4";
import Layout5 from "./Components/Layout5";
import Layout6 from "./Components/Layout6";
import Layout7 from "./Components/Layout7";
import Layout8 from "./Components/Layout8";
import Layout9 from "./Components/Layout9";
import LayoutHeader from "./Components/LayoutHeader";

function App() {
  useEffect(() => {
    return () => {};
  }, []);

  return (
    <div id="fxcontent" className="FlexContent">
      <Layout1 />
      <Layout2 />
      <Layout3 />
      <Layout4 />
      <Layout5 />
      <Layout6 />
      <Layout7 />
      <Layout8 />
      <Layout9 />
      <LayoutHeader />
    </div>
  );
}

export default App;
