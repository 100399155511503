import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React, { useEffect, useRef } from "react";

function Layout2() {
  const Layout_2_Image_Ref = useRef(null);
  const Layout_2_Image_AniState = useRef(false);

  // Layout_2_Image 애니메이션 관련 함수들
  function Layout_2_Image_AniPause() {
    Layout_2_Image_Ref.current.style.animationPlayState = "paused";
  }

  function Layout_2_Image_AniResume(Layout_2_Image_AniName) {
    Layout_2_Image_Ref.current.style.animation = "none";
    void Layout_2_Image_Ref.current.offsetWidth;
    Layout_2_Image_Ref.current.style.animation = Layout_2_Image_AniName;
  }

  function Layout_2_Image_Scroll() {
    const Layout_2_Image_Scroll_P =
      (window.scrollY / document.documentElement.scrollHeight) * 100;
    let Layout_2_Image_Scroll_S;
    const deviceWidth = window.innerWidth;

    if (deviceWidth < 768) {
      Layout_2_Image_Scroll_S = 0;
    } else if (deviceWidth >= 768 && deviceWidth < 1024) {
      Layout_2_Image_Scroll_S = 0;
    } else {
      Layout_2_Image_Scroll_S = 0;
    }

    if (Layout_2_Image_Scroll_P >= Layout_2_Image_Scroll_S) {
      if (!Layout_2_Image_AniState.current) {
        Layout_2_Image_AniState.current = true;
        Layout_2_Image_AniStart();
      }
    }
  }

  function Layout_2_Image_AniStart() {
    Layout_2_Image_AniState.current = true;
    Layout_2_Image_AniResume("Layout_2_Image_Anime 20s linear normal forwards");
    window.removeEventListener("scroll", Layout_2_Image_Scroll);
  }

  useEffect(() => {
    function Layout_2_Image_AniEnd(event) {
      const Layout_2_Image_AniName = event.animationName;

      if (Layout_2_Image_AniName === "Layout_2_Image_Anime") {
        Layout_2_Image_AniPause();
        setTimeout(() => {
          Layout_2_Image_AniResume(
            "Layout_2_Image_Anime 20s linear normal forwards"
          );
        }, 3000);
      }
    }
    const Layout_2_Image_Element = Layout_2_Image_Ref.current;

    if (Layout_2_Image_Element) {
      Layout_2_Image_Element.addEventListener(
        "animationend",
        Layout_2_Image_AniEnd
      );
    }

    return () => {
      if (Layout_2_Image_Element) {
        Layout_2_Image_Element.removeEventListener(
          "animationend",
          Layout_2_Image_AniEnd
        );
      }
    };
  }, []);

  return (
    <div className="Layout_2">
      <div className="Layout_2_GrayBG">
        <div className="Layout_2_PainText">
          <div className="Copy_6_400_A">
            경력증명서 발급, 그 험난한 여정에 지치지 않으신가요?
          </div>
        </div>
        <div className="Layout_2_Image" ref={Layout_2_Image_Ref}>
          <img
            src={require("../Assets/Images/ProblemAll.png")}
            alt="ProblemAll"
            style={{ width: "1000%", height: "auto" }}
          />
        </div>
      </div>
      <div
        className="Layout_2_SolutionText"
        data-aos="fade-up"
        data-aos-offset="120"
        data-aos-easing="ease"
        data-aos-duration="1000"
        data-aos-anchor-placement="top-bottom"
      >
        <div className="Copy_6_400_A">
          이제 써티드와 함께 <br />
          <span style={{ color: "rgb(0, 102, 255)" }}>훨씬</span> 간편한
          경력증명서 발급을 경험해보세요.
        </div>
      </div>
    </div>
  );
}
export default Layout2;
