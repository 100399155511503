import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

function Layout7() {
  return (
    <div className="Layout_7">
      <div className="Layout_7_Content">
        <div className="Copy_1_200_B">신뢰할 수 있는 경력</div>
        <div className="Copy_1_200_B" style={{ marginTop: "5px" }}>
          써티드와 함께
        </div>
        <div className="Layout_7_Button">
          <div className="Layout_7_Apple">
            <button
              className="Layout_7_ButtonBG"
              onClick={() => {
                window.location.href =
                  "https://certifie.onelink.me/2vIN/landing01";
              }}
            >
              <img
                src={require("../Assets/Images/appleLogoBLK.png")}
                alt="appleLogoBLK"
                width="100%"
                height="autoHeight"
                style={{
                  maxWidth: "32px",
                  display: "flex",
                  marginRight: "3%",
                }}
              />
              <div className="Copy_6_500_A">App Store</div>
            </button>
          </div>
          <div className="Layout_7_Google">
            <button
              className="Layout_7_ButtonBG"
              onClick={() => {
                window.location.href =
                  "https://certifie.onelink.me/2vIN/landing01";
              }}
            >
              <img
                src={require("../Assets/Images/GooglePlayLogo.png")}
                alt="GooglePlayLogo"
                width="100%"
                height="autoHeight"
                style={{
                  maxWidth: "32px",
                  display: "flex",
                  marginRight: "3%",
                }}
              />
              <div className="Copy_6_500_A">Google Play</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout7;
