import "../GlobalStyle.css";
import "../PCStyle.css";
import "../TabletStyle.css";
import "../MobileStyle.css";
import React from "react";

function LayoutHeader() {
  return (
    <div className="Layout_Header">
      <div className="Layout_Header_Logo">
        <img
          src={require("../Assets/Logo/CertedLogo.png")}
          alt="Logo"
          width="100%"
          height="auto"
        />
      </div>
    </div>
  );
}

export default LayoutHeader;
